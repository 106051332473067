<script setup>
import {computed, onMounted, reactive, ref, watch} from "vue";
import {useToast} from "primevue/usetoast";
import {useConfirm} from "primevue/useconfirm";
import {email, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {storeToRefs} from "pinia";
import {MemberStore} from "@/uhas/store/member";
import {getList} from 'country-list-with-dial-code-and-flag';

import provinces from "@/../../configs/location_th/provinces";
import districts from "@/../../configs/location_th/districts";
import subdistricts from "@/../../configs/location_th/subdistricts";

const toast = useToast();
const confirm = useConfirm();

const memberStore = MemberStore();
const {avatar, fullName, memberData} = storeToRefs(memberStore);

const countryList = getList().filter(c => c.code === "TH" || c.code === "LA")

/**
 * Data
 */
const loading = ref(false);
const blockOverlay = ref(false);

const achievement = ref([
	{ label: 'Non-Active', desc: 'สมาชิกทั่วไป Non Active' },
	{ label: 'Active', desc: 'สมาชิกทั่วไป' },
	{ label: 'Trader', desc: 'เทรดเดอร์ตัวจริง' },
	{ label: 'VIP', desc: 'เทรดเดอร์มือโปร' },
	{ label: 'VVIP', desc: 'เทรดเดอร์ VIP' },
	{ label: 'UhasX', desc: 'เทรดเดอร์ UhasX' }
]);

const basicInfoState = reactive({...memberStore.user});
const basicInfoValidation = useVuelidate({
	firstName: { required },
	lastName: { required },
	email: { email },
	birthday: { },

	country_code: {},
	province_id: {},
	district_id: {},
	subdistrict_id: {},
	zip_code: {},
	address: {}
}, basicInfoState);
const basicInfoSubmitted = ref(false);

const newAvatarFile = ref();
const newAvatarImg = ref(null);

const exnessClientIdState = reactive({exnessClientID: null});
const exnessClientIdValidation = useVuelidate({
	exnessClientID: { required }
}, exnessClientIdState);
const exnessClientIdSubmitted = ref(false);
const tempExness = ref(null);

const xmClientIdState = reactive({login_id: null});
const xmClientIdValidation = useVuelidate({
	login_id: { required }
}, xmClientIdState);
const xmClientIdSubmitted = ref(false);
const tempXm = ref(null);

const fbsClientIdState = reactive({login_id: null});
const fbsClientIdValidation = useVuelidate({
	login_id: { required }
}, fbsClientIdState);
const fbsClientIdSubmitted = ref(false);
const tempFbs = ref(null);

const hfmClientIdState = reactive({client_id: null});
const hfmClientIdValidation = useVuelidate({
	client_id: { required }
}, hfmClientIdState);
const hfmClientIdSubmitted = ref(false);
const tempHfm = ref(null);

const fxgtClientIdState = reactive({login_id: null});
const fxgtClientIdValidation = useVuelidate({
	login_id: { required }
}, fxgtClientIdState);
const fxgtClientIdSubmitted = ref(false);
const tempFxgt = ref(null);

const axiClientIdState = reactive({login: null});
const axiClientIdValidation = useVuelidate({
	login: { required }
}, axiClientIdState);
const axiClientIdSubmitted = ref(false);
const tempAxi = ref(null);

/**
 * Computed
 */
const exness = computed(() => memberStore.user?.exness || tempExness.value || null);
const xm = computed(() => memberStore.user?.xm || tempXm.value || null);
const fbs = computed(() => memberStore.user?.fbs || tempFbs.value || null);
const hfm = computed(() => memberStore.user?.hfm || tempHfm.value || null);
const fxgt = computed(() => memberStore.user?.fxgt || tempFxgt.value || null);
const axi = computed(() => memberStore.user?.axi || tempAxi.value || null);
const provinceLit = computed(() => basicInfoState.country_code === "TH" ? provinces : []);
const districtList = computed(() => !basicInfoState.province_id ? [] : districts.filter(item => item.ref === basicInfoState.province_id));
const subdistrictList = computed(() => !basicInfoState.district_id ? [] : subdistricts.filter(item => item.ref === basicInfoState.district_id));

/**
 * Watch
 */
watch(memberData, val => {
	basicInfoState.province_id = val.province_id;
}, { deep: true })

/**
 * Mounted
 */
 onMounted(() => {
	if (memberStore?.user?.phone && !basicInfoState.country_code) {
		if (memberStore.user.phone.includes("+66")) {
			basicInfoState.country_code = "TH";
		} else if (memberStore.user.phone.includes("+856")) {
			basicInfoState.country_code = "LA";
		}
	}
 });

/**
 * Actions
 */
const avatarChange = (evt) => {
	if (evt.target.files && evt.target.files[0]) {
		newAvatarImg.value = URL.createObjectURL(evt.target.files[0]);
		newAvatarFile.value = evt.target.files[0];
	}
}
const saveBasicInfo = async () => {
	basicInfoSubmitted.value = true;
	if (basicInfoValidation.value.$invalid) {
		return
	}

	loading.value = true;
	memberStore.saveBasicInfo({
		user: basicInfoState,
		newAvatarFile: newAvatarFile.value || null,
	}).then(result => {
		memberStore.user = {
			...memberStore.user,
			...result.user
		};

		toast.add({
			severity:"success",
			summary: "Success Message",
			detail: "Profile updated.",
			life: 10000
		});
	}).finally(() => {
		loading.value = false
	})
}
const requestLinkWithExness = async () => {
	exnessClientIdSubmitted.value = true;
	if (exnessClientIdValidation.value.$invalid) {
		return
	}

	blockOverlay.value = true;
	const result = await memberStore.getExnessAccount({...exnessClientIdState})
		.then(result => result)
		.catch(err => err);
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "profile-html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Exness's account has been linked with another member." : "Cannot get Exness's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		blockOverlay.value = false;
		return;
	}

	tempExness.value = result.exness;
	blockOverlay.value = false;
}
const confirmExnessLinkAccount = (event) => {
	confirm.require({
		target: event.currentTarget,
		message: "Are you sure you want to proceed?",
		icon: "pi pi-exclamation-triangle",
		accept: async () => {
			if (!tempExness.value) {
				toast.add({
					severity:"error",
					summary: "Error Message",
					detail: "Please specify the Client ID before linking the account.",
					life: 10000
				});

				return;
			}

			blockOverlay.value = true;
			const result = await memberStore.confirmLinkExnessAccount()
				.then(result => result)
				.catch(err => err);
			if (result.error) {
				const msg = result.error.message || `Cannot link Exness's account. ${result.error.code || ""}`;
				toast.add({
					severity:"error",
					summary: "Error Message",
					detail: msg,
					life: 10000
				});
			} else {
				memberStore.user.exness = result.exness;

				toast.add({
					severity:"success",
					summary: "Success Message",
					detail: "Your Exness's account has been linked.",
					life: 10000
				});
			}

			blockOverlay.value = false;
		}
	});
}
// const unlink = (event, account) => {
// 	confirm.require({
// 		target: event.currentTarget,
// 		message: "Are you sure you want to unlink the account?",
// 		icon: "pi pi-exclamation-triangle",
// 		accept: async () => {
// 			blockOverlay.value = true;
// 			const result = await memberStore.confirmUnlink({account})
// 				.then(result => result)
// 				.catch(err => err);
// 			if (result.error) {
// 				const msg = result.error.message || `Cannot unlink account. ${result.error.code || ""}`;
// 				toast.add({
// 					severity:"error",
// 					summary: "Error Message",
// 					detail: msg,
// 					life: 10000
// 				});
// 			} else {
// 				delete memberStore.user.exness;

// 				toast.add({
// 					severity:"success",
// 					summary: "Success Message",
// 					detail: "Your account has been unlinked.",
// 					life: 10000
// 				});
// 			}

// 			blockOverlay.value = false;
// 		}
// 	});
// }

const requestLinkWithXm = async () => {
	xmClientIdSubmitted.value = true;
	if (xmClientIdValidation.value.$invalid) {
		return
	}

	blockOverlay.value = true;
	const result = await memberStore.getXmAccount({...xmClientIdState})
		.then(result => result)
		.catch(err => err);
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "profile-html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Xm's account has been linked with another member." : "Cannot get Xm's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		blockOverlay.value = false;
		return;
	}

	tempXm.value = result.xm;
	blockOverlay.value = false;
}
const confirmXmLinkAccount = (event) => {
	confirm.require({
		target: event.currentTarget,
		message: "Are you sure you want to proceed?",
		icon: "pi pi-exclamation-triangle",
		accept: async () => {
			if (!tempXm.value) {
				toast.add({
					severity:"error",
					summary: "Error Message",
					detail: "Please specify the Login ID before linking the account.",
					life: 10000
				});

				return;
			}

			blockOverlay.value = true;
			const result = await memberStore.confirmLinkXmAccount()
				.then(result => result)
				.catch(err => err);
			if (result.error) {
				const msg = result.error.message || `Cannot link Xm's account. ${result.error.code || ""}`;
				toast.add({
					severity:"error",
					summary: "Error Message",
					detail: msg,
					life: 10000
				});
			} else {
				memberStore.user.xm = result.xm;

				toast.add({
					severity:"success",
					summary: "Success Message",
					detail: "Your Xm's account has been linked.",
					life: 10000
				});
			}

			blockOverlay.value = false;
		}
	});
}

const requestLinkWithFbs = async () => {
	fbsClientIdSubmitted.value = true;
	if (fbsClientIdValidation.value.$invalid) {
		return
	}

	blockOverlay.value = true;
	const result = await memberStore.getFbsAccount({...fbsClientIdState})
		.then(result => result)
		.catch(err => err);
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "profile-html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Fbs's account has been linked with another member." : "Cannot get Fbs's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		blockOverlay.value = false;
		return;
	}

	tempFbs.value = result.fbs;
	blockOverlay.value = false;
}
const confirmFbsLinkAccount = (event) => {
	confirm.require({
		target: event.currentTarget,
		message: "Are you sure you want to proceed?",
		icon: "pi pi-exclamation-triangle",
		accept: async () => {
			if (!tempFbs.value) {
				toast.add({
					severity:"error",
					summary: "Error Message",
					detail: "Please specify the Login ID before linking the account.",
					life: 10000
				});

				return;
			}

			blockOverlay.value = true;
			const result = await memberStore.confirmLinkFbsAccount()
				.then(result => result)
				.catch(err => err);
			if (result.error) {
				const msg = result.error.message || `Cannot link Fbs's account. ${result.error.code || ""}`;
				toast.add({
					severity:"error",
					summary: "Error Message",
					detail: msg,
					life: 10000
				});
			} else {
				memberStore.user.fbs = result.fbs;

				toast.add({
					severity:"success",
					summary: "Success Message",
					detail: "Your Fbs's account has been linked.",
					life: 10000
				});
			}

			blockOverlay.value = false;
		}
	});
}

const requestLinkWithHfm = async () => {
	hfmClientIdSubmitted.value = true;
	if (hfmClientIdValidation.value.$invalid) {
		return
	}

	blockOverlay.value = true;
	const result = await memberStore.getHfmAccount({...hfmClientIdState})
		.then(result => result)
		.catch(err => err);
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "profile-html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Hfm's account has been linked with another member." : "Cannot get Hfm's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		blockOverlay.value = false;
		return;
	}

	tempHfm.value = result.hfm;
	blockOverlay.value = false;
}
const confirmHfmLinkAccount = (event) => {
	confirm.require({
		target: event.currentTarget,
		message: "Are you sure you want to proceed?",
		icon: "pi pi-exclamation-triangle",
		accept: async () => {
			if (!tempHfm.value) {
				toast.add({
					severity:"error",
					summary: "Error Message",
					detail: "Please specify the Login ID before linking the account.",
					life: 10000
				});

				return;
			}

			blockOverlay.value = true;
			const result = await memberStore.confirmLinkHfmAccount()
				.then(result => result)
				.catch(err => err);
			if (result.error) {
				const msg = result.error.message || `Cannot link Hfm's account. ${result.error.code || ""}`;
				toast.add({
					severity:"error",
					summary: "Error Message",
					detail: msg,
					life: 10000
				});
			} else {
				memberStore.user.hfm = result.hfm;

				toast.add({
					severity:"success",
					summary: "Success Message",
					detail: "Your Hfm's account has been linked.",
					life: 10000
				});
			}

			blockOverlay.value = false;
		}
	});
}

const requestLinkWithFxgt = async () => {
	fxgtClientIdSubmitted.value = true;
	if (fxgtClientIdValidation.value.$invalid) {
		return
	}

	blockOverlay.value = true;
	const result = await memberStore.getFxgtAccount({...fxgtClientIdState})
		.then(result => result)
		.catch(err => err);
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "profile-html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Fxgt's account has been linked with another member." : "Cannot get Fxgt's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		blockOverlay.value = false;
		return;
	}

	tempFxgt.value = result.fxgt;
	blockOverlay.value = false;
}
const confirmFxgtLinkAccount = (event) => {
	confirm.require({
		target: event.currentTarget,
		message: "Are you sure you want to proceed?",
		icon: "pi pi-exclamation-triangle",
		accept: async () => {
			if (!tempFxgt.value) {
				toast.add({
					severity:"error",
					summary: "Error Message",
					detail: "Please specify the Login ID before linking the account.",
					life: 10000
				});

				return;
			}

			blockOverlay.value = true;
			const result = await memberStore.confirmLinkFxgtAccount()
				.then(result => result)
				.catch(err => err);
			if (result.error) {
				const msg = result.error.message || `Cannot link Fxgt's account. ${result.error.code || ""}`;
				toast.add({
					severity:"error",
					summary: "Error Message",
					detail: msg,
					life: 10000
				});
			} else {
				memberStore.user.fxgt = result.fxgt;

				toast.add({
					severity:"success",
					summary: "Success Message",
					detail: "Your Fxgt's account has been linked.",
					life: 10000
				});
			}

			blockOverlay.value = false;
		}
	});
}

const requestLinkWithAxi = async () => {
	axiClientIdSubmitted.value = true;
	if (axiClientIdValidation.value.$invalid) {
		return
	}

	blockOverlay.value = true;
	const result = await memberStore.getAxiAccount({...axiClientIdState})
		.then(result => result)
		.catch(err => err);
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "profile-html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Axi's account has been linked with another member." : "Cannot get Axi's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		blockOverlay.value = false;
		return;
	}

	tempAxi.value = result.axi;
	blockOverlay.value = false;
}
const confirmAxiLinkAccount = (event) => {
	confirm.require({
		target: event.currentTarget,
		message: "Are you sure you want to proceed?",
		icon: "pi pi-exclamation-triangle",
		accept: async () => {
			if (!tempAxi.value) {
				toast.add({
					severity:"error",
					summary: "Error Message",
					detail: "Please specify the Login ID before linking the account.",
					life: 10000
				});

				return;
			}

			blockOverlay.value = true;
			const result = await memberStore.confirmLinkAxiAccount()
				.then(result => result)
				.catch(err => err);
			if (result.error) {
				const msg = result.error.message || `Cannot link Axi's account. ${result.error.code || ""}`;
				toast.add({
					severity:"error",
					summary: "Error Message",
					detail: msg,
					life: 10000
				});
			} else {
				memberStore.user.axi = result.axi;

				toast.add({
					severity:"success",
					summary: "Success Message",
					detail: "Your Axi's account has been linked.",
					life: 10000
				});
			}

			blockOverlay.value = false;
		}
	});
}

const subdistrictChanged = v => {
	if (v && v.value) {
		basicInfoState.zip_code = subdistrictList.value.find(item => item.id === v.value).zip;
	} else {
		basicInfoState.zip_code = null;
	}
}

const achievementPercentage = i => {
	const level_score = memberStore.user?.level_score;
	if (i === 0) {
		return level_score >= 0 ? 100 : 0;
	} else if (i === 1) {
		return Math.min(level_score, 100);
	} else if (i === 2) {
		return level_score <= 100 ? 0 : Math.ceil((Math.min(level_score, 700) - 100) / 6);
	} else if (i === 3) {
		return level_score <= 700 ? 0 : Math.ceil((Math.min(level_score, 1500) - 700) / 8);
	}
	return level_score <= 1500 ? 0 : 100
}
const achievementMarker = i => {
	if (i === 0 ||
		(i === 1 & memberStore.user?.level_score >= 0) ||
		(i === 2 && memberStore.user?.level_score >= 100) ||
		(i === 3 && memberStore.user?.level_score >= 700) ||
		(i === 4 && memberStore.user?.level_score >= 1500) ||
		(i === 5 && memberStore.user?.level_score > 1500)
	) {
		return '#00e08f';
	}

	return '#dee2e6';
}
</script>

<style lang="scss">
.block-overlay {
	box-shadow: unset;

	.p-dialog-content {
		background: transparent;
    overflow: hidden;

		circle {
			fill: none;
		}
	}
}
</style>
<style lang="scss" scoped>
:v-deep(.block-overlay) {
	box-shadow: unset;
	.p-dialog-content {
		background: transparent;
    overflow: hidden;
	}
}

.avatar-badge {
	width: 24px;
	height: 24px;
	border: 2px solid white;
	position: absolute;
	bottom: 2%;
	right: 2%;
	border-radius: 100%;
}

::v-deep .p-card-body {
	padding: 2rem;
}
::v-deep {
	.p-timeline .p-timeline-event-marker {
		border-width: 7px;
	}
	.p-progressbar .p-progressbar-value {
		background: #00e08f;
	}
}
</style>

<template>
	<Toast />
	<Toast group="profile-html">
		<template #message="slotProps">
				<div class="flex flex-column">
						<div>
								<!-- <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i> -->
								<h4>{{slotProps.message.summary}}</h4>
								<p v-if="slotProps.message.code === 902">
									คุณไม่สามารถเชื่อมต่อได้เนื่องจากคุณได้มีการย้ายโบรกเกอร์แล้ว
								</p>
								<p v-else>
									ไม่พบเลขบัญชี ในระบบของเรา กรุณาติดต่อแอดมินในไลน์
									<a href='https://lin.ee/1eoK6Hd' target='_blank' class="text-900 text-xl font-bold">@uhasthailand</a> หรือ
									<a href='https://lin.ee/1eoK6Hd' target='_blank' class="text-900 text-xl font-bold">คลิก</a></p>
						</div>
				</div>
		</template>
	</Toast>

	<ConfirmPopup />
	<Dialog
		v-model:visible="blockOverlay"
		class="block-overlay"
		appendTo="self"
		:closeOnEscape="false"
		:showHeader="false"
		modal
	>
		<ProgressSpinner
			style="width:5vw;height:5vw"
			strokeWidth="8"
			fill="var(--surface-ground)"
			animationDuration=".5s"
		/>
	</Dialog>

	<div class="card widget-user-card">
		<div class="h-3rem flex justify-content-center">
			<label
				class="absolute w-8rem h-8rem flex justify-content-center"
				for="avatarUpload"
			>
				<img
					class="border-circle"
					:src="newAvatarImg || avatar"
				>
				<span class="avatar-badge bg-white">
					<i class="pi pi-camera"></i>
				</span>
				<input
					type="file"
					id="avatarUpload"
					accept="image/png, image/jpeg"
					class="hidden"
					:onchange="avatarChange"
				/>
			</label>
		</div>
		<div class="user-card-body">
			<div class="user-card-title">
				{{ fullName || memberStore.user?.phone || "???" }}
			</div>
			<div
				v-if="memberStore.user?.firstName"
				class="user-card-subtext"
			>
				{{ memberStore.user?.phone }}
			</div>

			<!-- <div class="grid user-card-stats">
					<div class="col-4">
							<i class="pi pi-info-circle"></i>
							<div>Info 1</div>
					</div>
					<div class="col-4">
							<i class="pi pi-bookmark"></i>
							<div>Info 2</div>
					</div>
					<div class="col-4">
							<i class="pi pi-check-square"></i>
							<div>Info 3</div>
					</div>
			</div> -->

			<Timeline
				:value="achievement"
				layout="horizontal"
				align="alternate"
				class="text-left ml-4"
			>
				<template #opposite> &nbsp; </template>
				<template #marker="{ index }">
					<span
						class="border-circle"
						:style="{ backgroundColor: achievementMarker(index), width: '15px', height: '14px' }"
					>
					</span>
				</template>
				<template #content="{ index, item }">
					<div
						:class="{'white-space-nowrap absolute': index === 0}"
						class="-ml-1 w-max"
						v-tooltip.top="item.desc"
					>
						{{ item.label }}
					</div>
				</template>
				<template #connector="{ index }">
					<ProgressBar
						:value="achievementPercentage(index)"
						:showValue="false"
						style="width: 100%; height: 6px; margin: 0 -2px 0 -2px;"
					/>
				</template>
			</Timeline>

			<Chip label="สถานะปรับตามปริมาณการซื้อขายของเทรดเดอร์ในแต่ละเดือน และมีสิทธิพิเศษต่างๆ ตามสถานะเทรดเดอร์" icon="pi pi-info-circle" />

			<form @submit.prevent="saveBasicInfo" class="p-fluid mt-8">

				<Divider align="left" type="dashed">
					<div class="inline-flex align-items-center">
						<i class="pi pi-user mr-2"></i>
						<b>Profile</b>
					</div>
				</Divider>

				<div class="p-fluid grid text-left mt-2">
					<div class="field col-12 md:col-6">
						<div class="p-float-label">
							<InputText
								id="firstName"
								v-model="basicInfoValidation.firstName.$model"
								:class="{'p-invalid':basicInfoValidation.firstName.$invalid && basicInfoSubmitted}"
							/>
							<label
								for="firstName"
								:class="{'p-error':basicInfoValidation.firstName.$invalid && basicInfoSubmitted}"
							>
								First name*
							</label>
						</div>
						<small v-if="(basicInfoValidation.firstName.$invalid && basicInfoSubmitted) || basicInfoValidation.firstName.$pending.$response" class="p-error">
							{{basicInfoValidation.firstName.required.$message.replace("Value", "First name")}}
						</small>
					</div>
					<div class="field col-12 md:col-6">
						<div class="p-float-label">
							<InputText
								id="lastName"
								v-model="basicInfoValidation.lastName.$model"
								:class="{'p-invalid':basicInfoValidation.lastName.$invalid && basicInfoSubmitted}"
							/>
							<label
								for="lastName"
								:class="{'p-error':basicInfoValidation.lastName.$invalid && basicInfoSubmitted}"
							>
								Last name*
							</label>
						</div>
						<small v-if="(basicInfoValidation.lastName.$invalid && basicInfoSubmitted) || basicInfoValidation.lastName.$pending.$response" class="p-error">
							{{basicInfoValidation.lastName.required.$message.replace("Value", "Last name")}}
						</small>
					</div>
					<div class="field col-12 md:col-6">
						<div class="p-float-label">
							<InputText
								id="email"
								v-model="basicInfoValidation.email.$model"
								:class="{'p-invalid':basicInfoValidation.email.$invalid && basicInfoSubmitted}"
							/>
							<label
								for="email"
								:class="{'p-error':basicInfoValidation.email.$invalid && basicInfoSubmitted}"
							>
								Email
							</label>
						</div>
						<small v-if="(basicInfoValidation.email.$invalid && basicInfoSubmitted) || basicInfoValidation.email.$pending.$response" class="p-error">
							{{basicInfoValidation.email.email.$message.replace("Value", "Email")}}
						</small>
					</div>
					<div class="field col-12 md:col-6">
						<div class="p-float-label">
							<InputText
								id="phone"
								v-model="basicInfoState.phone"
								disabled
							/>
							<label for="phone">Phone*</label>
						</div>
					</div>
					<div class="field col-12 md:col-6">
						<div class="p-float-label">
							<Calendar
								inputId="birthday"
								v-model="basicInfoValidation.birthday.$model"
								dateFormat="yy-mm-dd"
								:class="{'p-invalid':basicInfoValidation.birthday.$invalid && basicInfoSubmitted}"
								:manualInput="false"
							/>
							<label
								for="birthday"
								:class="{'p-error':basicInfoValidation.birthday.$invalid && basicInfoSubmitted}"
							>
								Birthday
							</label>
						</div>
					</div>
				</div>

				<Divider align="left" type="dashed">
					<div class="inline-flex align-items-center">
						<i class="pi pi-home mr-2"></i>
						<b>Address</b>
					</div>
				</Divider>

				<div class="p-fluid grid text-left mt-2">
					<div class="field col-12 md:col-6">
						<div class="p-float-label">
							<InputText
								id="address"
								v-model="basicInfoValidation.address.$model"
								:class="{'p-invalid':basicInfoValidation.address.$invalid && basicInfoSubmitted}"
							/>
							<label
								for="address"
								:class="{'p-error':basicInfoValidation.address.$invalid && basicInfoSubmitted}"
							>
								Address
							</label>
						</div>
					</div>
					<div class="field col-12 md:col-6">
						<div class="p-float-label">
							<Dropdown
								v-model="basicInfoValidation.country_code.$model"
								id="country_code"
								:options="countryList"
								:optionLabel="(x) => `${x.flag} ${x.name}`"
								optionValue="code"
								@change="basicInfoState.province_id = null; basicInfoState.district_id = null; basicInfoState.subdistrict_id = null; basicInfoState.zip_code = null;"
							/>
							<label
								for="country_code"
								:class="{'p-error':basicInfoValidation.country_code.$invalid && basicInfoSubmitted}"
							>
								Country
							</label>
						</div>
					</div>
					<div class="field col-12 md:col-6">
						<div class="p-float-label">
							<Dropdown
								v-model="basicInfoValidation.province_id.$model"
								id="province_id"
								optionLabel="name"
								optionValue="id"
								placeholder="Province"
								:disabled="provinceLit.length === 0"
								:options="provinceLit"
								:class="{'p-invalid':basicInfoValidation.province_id.$invalid && basicInfoSubmitted}"
								filter
								@change="basicInfoState.district_id = null; basicInfoState.subdistrict_id = null; basicInfoState.zip_code = null;"
							/>
							<label
								for="province_id"
								:class="{'p-error':basicInfoValidation.province_id.$invalid && basicInfoSubmitted}"
							>
								Province
							</label>
						</div>
					</div>
					<div class="field col-12 md:col-6">
						<div class="p-float-label">
							<Dropdown
								v-model="basicInfoValidation.district_id.$model"
								id="district_id"
								optionLabel="name"
								optionValue="id"
								placeholder="District"
								:disabled="districtList.length === 0"
								:options="districtList"
								:class="{'p-invalid':basicInfoValidation.district_id.$invalid && basicInfoSubmitted}"
								filter
								@change="basicInfoState.subdistrict_id = null; basicInfoState.zip_code = null;"
							/>
							<label
								for="district_id"
								:class="{'p-error':basicInfoValidation.district_id.$invalid && basicInfoSubmitted}"
							>
								District
							</label>
						</div>
					</div>
					<div class="field col-12 md:col-6">
						<div class="p-float-label">
							<Dropdown
								v-model="basicInfoValidation.subdistrict_id.$model"
								id="subdistrict_id"
								optionLabel="name"
								optionValue="id"
								placeholder="Subdistrict"
								:disabled="subdistrictList.length === 0"
								:options="subdistrictList"
								:class="{'p-invalid':basicInfoValidation.subdistrict_id.$invalid && basicInfoSubmitted}"
								filter
								@change="subdistrictChanged"
							/>
							<label
								for="subdistrict_id"
								:class="{'p-error':basicInfoValidation.subdistrict_id.$invalid && basicInfoSubmitted}"
							>
								Subdistrict
							</label>
						</div>
					</div>
					<div class="field col-12 md:col-6">
						<div class="p-float-label">
							<InputText
								id="zip_code"
								v-model="basicInfoValidation.zip_code.$model"
								:class="{'p-invalid':basicInfoValidation.zip_code.$invalid && basicInfoSubmitted}"
							/>
							<label
								for="zip_code"
								:class="{'p-error':basicInfoValidation.zip_code.$invalid && basicInfoSubmitted}"
							>
								Zipcode
							</label>
						</div>
					</div>
				</div>

			</form>
		</div>
		<div class="text-left p-5 pt-0">
			<Button
				:label="loading ? 'Saving...' : 'Save changes'"
				:loading="loading"
				@click="saveBasicInfo"
			/>
		</div>
	</div>

	<Card>
		<template #title>
			<div class="flex">
				Exness

				<!-- <Button
					v-if="memberStore.user?.exness"
					label="ยกเลิกการเชื่อมบัญชี"
					icon="pi pi-times"
					class="p-button-outlined p-button-danger p-button-sm ml-auto"
					@click="unlink($event, 'exness')"
				/> -->
			</div>
    </template>
		<template #content>
			<div
				v-if="!exness"
				class="field w-max"
			>
				<div class="p-inputgroup">
					<InputText
						v-model="exnessClientIdValidation.exnessClientID.$model"
						placeholder="เลขบัญชีจริง exness"
						:class="{'p-error': exnessClientIdValidation.exnessClientID.$invalid && exnessClientIdSubmitted}"
					/>
					<Button
						icon="pi pi-link"
						class="p-button-outlined"
						label="ผูกบัญชี exness"
						@click="requestLinkWithExness"
					/>
					<!-- <InlineMessage>Exness Client ID is required</InlineMessage> -->
				</div>
				<small v-if="(exnessClientIdValidation.exnessClientID.$invalid && exnessClientIdSubmitted) || exnessClientIdValidation.exnessClientID.$pending.$response" class="p-error">
					{{exnessClientIdValidation.exnessClientID.required.$message.replace("Value", "เลขบัญชีจริง exness")}}
				</small>
			</div>

			<div v-else>
				<Button
					v-if="!memberStore.user?.exness"
					class="mb-4"
					icon="pi pi-link"
					:label="`ยืนยันการผูกบัญชี exness: ${exness.client_account}`"
					@click="confirmExnessLinkAccount($event)"
				/>

				<label class="block text-900 font-medium mb-2" for="client_uid">Client UID</label>
				<InputText v-model="exness.client_uid" id="client_uid" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="client_account_type">Client Account Type</label>
				<InputText v-model="exness.client_account_type" id="client_account_type" type="text" class="w-full mb-3" readonly />

				<!-- <label class="block text-900 font-medium mb-2" for="created_at">Created At</label>
				<InputText :value="(new Date(exness.created_at)).toLocaleString()" id="created_at" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="updated_at">Updated At</label>
				<InputText :value="(new Date(exness.updated_at)).toLocaleString()" id="updated_at" type="text" class="w-full mb-3" readonly /> -->

				<Button
					v-if="!memberStore.user?.exness"
					class="mt-4"
					icon="pi pi-link"
					:label="`ยืนยันการผูกบัญชี exness: ${exness.client_account}`"
					@click="confirmExnessLinkAccount($event)"
				/>
			</div>
		</template>
	</Card>

	<Card class="mt-4">
		<template #title>
			<div class="flex">
				Xm
			</div>
    </template>
		<template #content>
			<div
				v-if="!xm"
				class="field w-max"
			>
				<div class="p-inputgroup">
					<InputText
						v-model="xmClientIdValidation.login_id.$model"
						placeholder="เลขบัญชีจริง xm"
						:class="{'p-error': xmClientIdValidation.login_id.$invalid && xmClientIdSubmitted}"
					/>
					<Button
						icon="pi pi-link"
						class="p-button-outlined"
						label="ผูกบัญชี xm"
						@click="requestLinkWithXm"
					/>
				</div>
				<small v-if="(xmClientIdValidation.login_id.$invalid && xmClientIdSubmitted) || xmClientIdValidation.login_id.$pending.$response" class="p-error">
					{{xmClientIdValidation.login_id.required.$message.replace("Value", "เลขบัญชีจริง xm")}}
				</small>
			</div>

			<div v-else>
				<Button
					v-if="!memberStore.user?.xm"
					class="mb-4"
					icon="pi pi-link"
					:label="`ยืนยันการผูกบัญชี xm: ${xm.login_id || xm.loginId}`"
					@click="confirmXmLinkAccount($event)"
				/>

				<label class="block text-900 font-medium mb-2" for="campaign_name">Campaign name</label>
				<InputText :value="xm.campaign_name || xm.campaignName" id="campaign_name" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="campaign_id">Campaign ID</label>
				<InputText :value="xm.campaign_id || xm.campaignId" id="campaign_id" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="login_id">Login ID</label>
				<InputText :value="xm.login_id || xm.loginId" id="login_id" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="account_type">Account type</label>
				<InputText :value="xm.account_type || xm.accountType" id="account_type" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="signup_date">Signup date</label>
				<InputText :value="(new Date(xm.signup_date || xm.signupDate)).toLocaleString()" id="signup_date" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="activation_date">Activation date</label>
				<InputText :value="(xm.activation_date || xm.activationDate) ? (new Date(xm.activation_date || xm.activationDate)).toLocaleString() : null" id="activation_date" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="server_name">Server name</label>
				<InputText :value="xm.server_name || xm.serverName" id="server_name" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="validated">Validated</label>
				<InputText :value="xm.validated" id="validated" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="archived">Archived</label>
				<InputText :value="xm.archived" id="archived" type="text" class="w-full mb-3" readonly />

				<Button
					v-if="!memberStore.user?.xm"
					class="mt-4"
					icon="pi pi-link"
					:label="`ยืนยันการผูกบัญชี xm: ${xm.login_id || xm.loginId}`"
					@click="confirmXmLinkAccount($event)"
				/>
			</div>
		</template>
	</Card>

	<Card class="mt-4">
		<template #title>
			<div class="flex">
				Hfm
			</div>
		</template>
		<template #content>
			<div
				v-if="!hfm"
				class="field w-max"
			>
				<div class="p-inputgroup">
					<InputText
						v-model="hfmClientIdValidation.client_id.$model"
						placeholder="เลขบัญชีจริง hfm"
						:class="{'p-error': hfmClientIdValidation.client_id.$invalid && hfmClientIdSubmitted}"
					/>
					<Button
						icon="pi pi-link"
						class="p-button-outlined"
						label="ผูกบัญชี hfm"
						@click="requestLinkWithHfm"
					/>
				</div>
				<small v-if="(hfmClientIdValidation.client_id.$invalid && hfmClientIdSubmitted) || hfmClientIdValidation.client_id.$pending.$response" class="p-error">
					{{hfmClientIdValidation.client_id.required.$message.replace("Value", "เลขบัญชีจริง hfm")}}
				</small>
			</div>

			<div v-else>
				<Button
					v-if="!memberStore.user?.hfm"
					class="mb-4"
					icon="pi pi-link"
					:label="`ยืนยันการผูกบัญชี hfm: ${hfm.client_id || hfm.id}`"
					@click="confirmHfmLinkAccount($event)"
				/>

				<label class="block text-900 font-medium mb-2" for="from_hfm_id">From HFM ID</label>
				<InputText :value="hfm.client_id || hfm.from_hfm_id || hfm.id" id="from_hfm_id" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="type">Type</label>
				<InputText :value="hfm.type" id="type" type="text" class="w-full mb-3" readonly />

				<!-- label class="block text-900 font-medium mb-2" for="last_trade">Last Trade</label>
				<InputText :value="(new Date(hfm.last_trade)).toLocaleString()" id="last_trade" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="volume">Volume</label>
				<InputText :value="hfm.volume" id="volume" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="balance">Balance</label>
				<InputText :value="hfm.balance" id="balance" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="commission">Commission</label>
				<InputText :value="hfm.commission" id="commission" type="text" class="w-full mb-3" readonly / -->

				<label class="block text-900 font-medium mb-2" for="account_currency">Account Currency</label>
				<InputText :value="hfm.account_currency" id="account_currency" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="country">Country</label>
				<InputText :value="hfm.country" id="country" type="text" class="w-full mb-3" readonly />

				<!-- label class="block text-900 font-medium mb-2" for="rebates_paid">Rebates Paid</label>
				<InputText :value="hfm.rebates_paid" id="rebates_paid" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="rebates_unpaid">Rebates Unpaid</label>
				<InputText :value="hfm.rebates_unpaid" id="rebates_unpaid" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="rebates_rejected">Rebates Rejected</label>
				<InputText :value="hfm.rebates_rejected" id="rebates_rejected" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="first_trade">First Trade</label>
				<InputText :value="(new Date(hfm.first_trade)).toLocaleString()" id="first_trade" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="first_funding">First Funding</label>
				<InputText :value="(new Date(hfm.first_funding)).toLocaleString()" id="first_funding" type="text" class="w-full mb-3" readonly / -->

				<label class="block text-900 font-medium mb-2" for="registration">Registration</label>
				<InputText :value="(new Date(hfm.registration)).toLocaleString()" id="registration" type="text" class="w-full mb-3" readonly />

				<!-- label class="block text-900 font-medium mb-2" for="server">Server</label>
				<InputText :value="hfm.server" id="server" type="text" class="w-full mb-3" readonly / -->

				<label class="block text-900 font-medium mb-2" for="platform">Platform</label>
				<InputText :value="hfm.platform" id="platform" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="conversion_device">Conversion Device</label>
				<InputText :value="hfm.conversion_device" id="conversion_device" type="text" class="w-full mb-3" readonly />

				<!-- label class="block text-900 font-medium mb-2" for="deposits">Deposits</label>
				<InputText :value="hfm.deposits" id="deposits" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="withdrawals">Withdrawals</label>
				<InputText :value="hfm.withdrawals" id="withdrawals" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="name">Name</label>
				<InputText :value="hfm.name" id="name" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="email">Email</label>
				<InputText :value="hfm.email" id="email" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="equity">Equity</label>
				<InputText :value="hfm.equity" id="equity" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="margin">Margin</label>
				<InputText :value="hfm.margin" id="margin" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="free_margin">Free Margin</label>
				<InputText :value="hfm.free_margin" id="free_margin" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="campaign">Campaign</label>
				<InputText :value="hfm.campaign" id="campaign" type="text" class="w-full mb-3" readonly / -->

				<label class="block text-900 font-medium mb-2" for="leverage">Leverage</label>
				<InputText :value="hfm.leverage" id="leverage" type="text" class="w-full mb-3" readonly />

				<!-- label class="block text-900 font-medium mb-2" for="bonus_type">Bonus Type</label>
				<InputText :value="hfm.bonus_type" id="bonus_type" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="trades">Trades</label>
				<InputText :value="hfm.trades" id="trades" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="bonus">Bonus</label>
				<InputText :value="hfm.bonus" id="bonus" type="text" class="w-full mb-3" readonly / -->

				<Button
					v-if="!memberStore.user?.hfm"
					class="mt-4"
					icon="pi pi-link"
					:label="`ยืนยันการผูกบัญชี hfm: ${hfm.client_id || hfm.id}`"
					@click="confirmHfmLinkAccount($event)"
				/>
			</div>
		</template>
	</Card>

	<Card class="mt-4">
		<template #title>
			<div class="flex">
				Fxgt
			</div>
		</template>
		<template #content>
			<div
				v-if="!fxgt"
				class="field w-max"
			>
				<div class="p-inputgroup">
					<InputText
						v-model="fxgtClientIdValidation.login_id.$model"
						placeholder="เลขบัญชีจริง fxgt"
						:class="{'p-error': fxgtClientIdValidation.login_id.$invalid && fxgtClientIdSubmitted}"
					/>
					<Button
						icon="pi pi-link"
						class="p-button-outlined"
						label="ผูกบัญชี fxgt"
						@click="requestLinkWithFxgt"
					/>
				</div>
				<small v-if="(fxgtClientIdValidation.login_id.$invalid && fxgtClientIdSubmitted) || fxgtClientIdValidation.login_id.$pending.$response" class="p-error">
					{{fxgtClientIdValidation.login_id.required.$message.replace("Value", "เลขบัญชีจริง fxgt")}}
				</small>
			</div>

			<div v-else>
				<Button
					v-if="!memberStore.user?.fxgt"
					class="mb-4"
					icon="pi pi-link"
					:label="`ยืนยันการผูกบัญชี fxgt: ${fxgt.login_id}`"
					@click="confirmFxgtLinkAccount($event)"
				/>

				<label class="block text-900 font-medium mb-2" for="from_fxgt_id">From Fxgt ID</label>
				<InputText :value="fxgt.from_fxgt_id" id="from_fxgt_id" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="login_id">Login ID</label>
				<InputText :value="fxgt.login_id" id="login_id" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="client_id">Client ID</label>
				<InputText :value="fxgt.client_id" id="client_id" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="server_id">Server ID</label>
				<InputText :value="fxgt.server_id" id="server_id" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="platform">Platform</label>
				<InputText :value="fxgt.platform" id="platform" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="referral_code">Referral Code</label>
				<InputText :value="fxgt.referral_code" id="referral_code" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="country_name">Country Name</label>
				<InputText :value="fxgt.country_name || fxgt.country?.name" id="country_name" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="currency_name">Currency Name</label>
				<InputText :value="fxgt.currency_name || fxgt.currency?.name" id="currency_name" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="account_type_category_name">Account Type Category Name</label>
				<InputText :value="fxgt.account_type_category_name || fxgt.account_type_category?.name" id="account_type_category_name" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="referred_by">Referred By</label>
				<InputText :value="fxgt.referred_by" id="referred_by" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="created_on">Created On</label>
				<InputText :value="fxgt.created_on" id="created_on" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="affiliation_status">Affiliation Status</label>
				<InputText :value="fxgt.affiliation_status" id="affiliation_status" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="ftd_date">Ftd Date</label>
				<InputText :value="fxgt.ftd_date" id="ftd_date" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="ftd_amount_usd">Ftd Amount Usd</label>
				<InputText :value="fxgt.ftd_amount_usd || fxgt.ftd_amount?.usd || 0" id="ftd_amount_usd" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="first_trade">First Trade</label>
				<InputText :value="fxgt.first_trade" id="first_trade" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="last_trade">Last Trade</label>
				<InputText :value="fxgt.last_trade" id="last_trade" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="activation_date">Activation Date</label>
				<InputText :value="fxgt.activation_date" id="activation_date" type="text" class="w-full mb-3" readonly />

				<!--label class="block text-900 font-medium mb-2" for="commission_usd">Commission Usd</label>
				<InputText :value="fxgt.commission_usd || fxgt.commission?.usd || 0" id="commission_usd" type="text" class="w-full mb-3" readonly /-->

				<label class="block text-900 font-medium mb-2" for="deposits_usd">Deposits Usd</label>
				<InputText :value="fxgt.deposits_usd || fxgt.deposits?.usd || 0" id="deposits_usd" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="funds_out_usd">Funds Out Usd</label>
				<InputText :value="fxgt.funds_out_usd || fxgt.funds_out?.usd || 0" id="funds_out_usd" type="text" class="w-full mb-3" readonly />

				<!--label class="block text-900 font-medium mb-2" for="gt_lots">Gt Lots</label>
				<InputText :value="fxgt.gt_lots" id="gt_lots" type="text" class="w-full mb-3" readonly /-->

				<label class="block text-900 font-medium mb-2" for="status">Status</label>
				<InputText :value="fxgt.status" id="status" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="equity">Equity</label>
				<InputText :value="fxgt.equity" id="equity" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="created_at">Created At</label>
				<InputText :value="fxgt.created_at" id="created_at" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="updated_at">Updated At</label>
				<InputText :value="fxgt.updated_at" id="updated_at" type="text" class="w-full mb-3" readonly />

				<Button
					v-if="!memberStore.user?.fxgt"
					class="mt-4"
					icon="pi pi-link"
					:label="`ยืนยันการผูกบัญชี fxgt: ${fxgt.login_id}`"
					@click="confirmFxgtLinkAccount($event)"
				/>
			</div>
		</template>
	</Card>

	<Card class="mt-4">
		<template #title>
			<div class="flex">
				Axi
			</div>
		</template>
		<template #content>
			<div
				v-if="!axi"
				class="field w-max"
			>
				<div class="p-inputgroup">
					<InputText
						v-model="axiClientIdValidation.login.$model"
						placeholder="เลขบัญชีจริง axi"
						:class="{'p-error': axiClientIdValidation.login.$invalid && axiClientIdSubmitted}"
					/>
					<Button
						icon="pi pi-link"
						class="p-button-outlined"
						label="ผูกบัญชี axi"
						@click="requestLinkWithAxi"
					/>
				</div>
				<small v-if="(axiClientIdValidation.login.$invalid && axiClientIdSubmitted) || axiClientIdValidation.login.$pending.$response" class="p-error">
					{{axiClientIdValidation.login.required.$message.replace("Value", "เลขบัญชีจริง axi")}}
				</small>
			</div>

			<div v-else>
				<Button
					v-if="!memberStore.user?.axi"
					class="mb-4"
					icon="pi pi-link"
					:label="`ยืนยันการผูกบัญชี axi: ${axi.login}`"
					@click="confirmAxiLinkAccount($event)"
				/>

				<label class="block text-900 font-medium mb-2" for="axi_login">Login</label>
				<InputText :value="axi.login" id="axi_login" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_email">Email</label>
				<InputText :value="axi.email" id="axi_email" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_user_group">User group</label>
				<InputText :value="axi.user_group" id="axi_user_group" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_leverage">Leverage</label>
				<InputText :value="axi.leverage" id="axi_leverage" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_comment">Comment</label>
				<InputText :value="axi.comment" id="axi_comment" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_phone">Phone</label>
				<InputText :value="axi.phone" id="axi_phone" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_name">Name</label>
				<InputText :value="axi.name" id="axi_name" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_country">Country</label>
				<InputText :value="axi.country" id="axi_country" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_city">City</label>
				<InputText :value="axi.city" id="axi_city" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_state">State</label>
				<InputText :value="axi.state" id="axi_state" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_zipcode">Zipcode</label>
				<InputText :value="axi.zip_code" id="axi_zipcode" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_address">Address</label>
				<InputText :value="axi.address" id="axi_address" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_credit">Credit</label>
				<InputText :value="axi.credit || 0" id="axi_credit" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_base_currency">Base currency</label>
				<InputText :value="axi.base_currency || ''" id="axi_base_currency" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_register_date">Register date</label>
				<InputText :value="axi.register_date || ''" id="axi_register_date" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_agent_account">Agent account</label>
				<InputText :value="axi.agent_account || ''" id="axi_agent_account" type="text" class="w-full mb-3" readonly />

				<label class="block text-900 font-medium mb-2" for="axi_direct">Direct</label>
				<InputText :value="!!axi.is_direct" id="axi_direct" type="text" class="w-full mb-3" readonly />

				<Button
					v-if="!memberStore.user?.axi"
					class="mt-4"
					icon="pi pi-link"
					:label="`ยืนยันการผูกบัญชี axi: ${axi.login}`"
					@click="confirmAxiLinkAccount($event)"
				/>
			</div>
		</template>
	</Card>

	<BlockUI blocked>
		<Card class="mt-4">
			<template #title>
				<div class="flex">
					Fbs
					<span class="ml-auto p-error">Comming Soon...</span>
				</div>
			</template>
			<template #content>
				<div
					v-if="!fbs"
					class="field w-max"
				>
					<div class="p-inputgroup">
						<InputText
							v-model="fbsClientIdValidation.login_id.$model"
							placeholder="เลขบัญชีจริง fbs"
							:class="{'p-error': fbsClientIdValidation.login_id.$invalid && fbsClientIdSubmitted}"
						/>
						<Button
							icon="pi pi-link"
							class="p-button-outlined"
							label="ผูกบัญชี fbs"
							@click="requestLinkWithFbs"
						/>
					</div>
					<small v-if="(fbsClientIdValidation.login_id.$invalid && fbsClientIdSubmitted) || fbsClientIdValidation.login_id.$pending.$response" class="p-error">
						{{fbsClientIdValidation.login_id.required.$message.replace("Value", "เลขบัญชีจริง fbs")}}
					</small>
				</div>

				<div v-else>
					<Button
						v-if="!memberStore.user?.fbs"
						class="mb-4"
						icon="pi pi-link"
						:label="`ยืนยันการผูกบัญชี fbs: ${fbs.login_id || fbs.loginId}`"
						@click="confirmFbsLinkAccount($event)"
					/>

					<label class="block text-900 font-medium mb-2" for="campaign_name">Campaign name</label>
					<InputText :value="fbs.campaign_name || fbs.campaignName" id="campaign_name" type="text" class="w-full mb-3" readonly />

					<Button
						v-if="!memberStore.user?.fbs"
						class="mt-4"
						icon="pi pi-link"
						:label="`ยืนยันการผูกบัญชี fbs: ${fbs.login_id || fbs.loginId}`"
						@click="confirmFbsLinkAccount($event)"
					/>
				</div>
			</template>
		</Card>
	</BlockUI>
</template>
